html {
    font-size: 62.5%;
}
body {
    font-size: 1.6rem;
    line-height: 1.4;
    color: var(--ion-color-dark);
}
h1 {
    margin-bottom: 16px;
    font-family: "Ubuntu";
    font-size: 3.8rem;
    font-weight: normal;
    line-height: 1.1;
    text-align: center;
}
p {
    margin: 16px 0;

    + p {
        margin-top: 0;
    }
}
.logo {
    display: block;
    margin: 50px auto 30px;
    text-align: center;
}

.message {
    margin-bottom: 20px;
    padding: 10px 20px;
    border-radius: var(--flow-border-radius);
}
.message--error {
    color: var(--ion-color-light);
    background: var(--ion-color-danger);
}

.action {
    width: 100%;

    &--bottom {
        margin-top: auto;
        margin-bottom: 30px;

        .button {
            margin-bottom: 0;
        }
    }
}