.dongle--ok {
    &::part(background) {
        --background: url(../../assets/img-dongle-v1.jpg) no-repeat top center;
    }
    .check-icon {
        margin: 0 auto;
    }
    h1 {
        margin-top: 16px;
    }
    h2 {
        align-self: stretch;
        margin-bottom: 0;
        padding-bottom: 10px;
        border-bottom: 2px solid var(--ion-color-dark);
        font-size: 1.9rem;
    }
    ion-list {
        align-self: stretch;
        margin-bottom: 16px;
        background: transparent;

        ion-item {
            --padding-start: 0;
            --inner-padding-end: 0;
            --border-color: var(--ion-color-dark);
            font-size: 1.6rem;
        }
    }
    ion-note {
        padding-top: 11px;
        font-size: 1.6rem;
        font-weight: 700;
    }
    .dongle-text {
        max-width: none;
        margin: auto 0;
    }
    .action {
        margin-top: auto;
    }
}