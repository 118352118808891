.dashboard {
    &::part(scroll) {
        padding-top: max(var(--ion-safe-area-top), 16px);
        padding-bottom: 0;
    }
    h1 {
        margin-top: 10px;
        margin-bottom: 8px;
        font-size: 2.5rem;
    }
}
ion-item.item.hint {
    --padding-start: 10px;
    --inner-padding-end: 10px;
    --border-radius: var(--flow-border-radius);
    margin: 10px 16px;

    ion-label {
        font-size: 1.2rem;
    }
    ion-icon {
        margin-right: 8px;

        &:last-child {
            margin-right: 0;
            margin-left: 8px;
        }
    }
}