.notifications {
    &::part(scroll) {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: max(var(--ion-safe-area-top), 16px);
        padding-bottom: max(var(--ion-safe-area-bottom), 16px);
        text-align: center;
    }
    .notification-icon {
        margin-top: 30px;
    }
    .notification-link {
        margin-top: auto;
        color: var(--ion-color-light);
    }
}