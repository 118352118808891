ion-modal {
    ion-header {
        &::after {
            display: none;
        }
    }
    ion-toolbar:last-of-type {
        .ios & {
            --border-width: 0;
        }
    }
    ion-content {
        &::part(background) {
            --background: var(--ion-color-light);
        }
    }
}