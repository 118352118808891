ion-item.item {
    --padding-start: 0;
    --inner-padding-end: 0;
    --ion-item-background: transparent;
    flex-shrink: 0;
    font-size: 1.6rem;

    &::part(native) {
        --border-color: var(--ion-color-dark);
    }
    .label-stacked {
        font-size: 1.6rem;

        .md & {
            margin-top: 10px;
            margin-bottom: 4px;
            transform: none;
        }
    }
    ion-input {
        margin-top: 6px;
        margin-right: 0;
        margin-bottom: 6px;
    }
    &.select-interface-option {
        --padding-start: 8px;
        --inner-padding-end: 8px;

        &:last-child {
            &::part(native) {
                --inner-border-width: 0;
            }
        }
    }
}
.ios {
    ion-label.ion-text-wrap {
        font-size: 1.6rem;
        line-height: unset;
    }
}

ion-item-divider.item {
    --padding-top: 16px;
    --padding-start: 0;
    --inner-padding-end: 0;
    --color: var(--ion-color-dark);
    --background: transparent;
    border-bottom: 2px solid var(--ion-color-dark);
    font-size: 1.6rem;

    ion-label {
        width: 100%;
        margin: 0;
        text-align: center;
        font-weight: 600;

        &.ion-text-wrap {
            font-size: inherit;
            line-height: normal;
        }
    }
}