.wifi {
    &::part(scroll) {
        --padding-end: 0;
        --padding-start: 0;
        display: flex;
        flex-direction: column;
        padding-top: max(var(--ion-safe-area-top), 16px);
        padding-bottom: 0;
    }
    &-list {
        flex-grow: 1;
        padding: 64px 0 var(--ion-padding, 16px);
        border-top-left-radius: var(--flow-border-radius);
        border-top-right-radius: var(--flow-border-radius);
        background: var(--ion-color-light);

        ion-list {
            padding-left: var(--ion-padding, 16px);
            padding-right: var(--ion-padding, 16px);
            background: transparent;
        }
    }
    &-info {
        padding-left: 20px;
        padding-right: 20px;
    }
}