.charts {
    &__dateselect--buttons {
        display: inline-flex;
        border-radius: var(--flow-border-radius);
        background: #fff;

        button{
            border: 0;
            height: 40px;
            background-color: transparent;
            color: var(--flow-color-greyBlue);
            cursor: pointer;
            padding: 0 15px;
            font-size: 1.2em;
            line-height: 1;
            text-transform: uppercase;
            border-radius: 0;

            // &:disabled,
            // &[disabled]{
            //     background-color: var(--flow-color-backColor);
            // }
        }
        .calendar__trigger{
            flex-grow: 1;
            font-weight: 700;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 25px;
        
            span{
                font-size: .6em;
            }
        }
    }
}

.react-calendar {
    position: absolute;
    top: 45px;
    left: 50%;
    width: 320px;
    max-width: 100%;
    margin-left: -160px;
    border-radius: 4px;
    border: 1px solid var(--ion-color-medium);
    line-height: 1.125em;
    background: var(--ion-color-light);
  
    button {
        margin: 0;
        border: 0;
        outline: none;
    }
    &__navigation {
        display: flex;
        height: 44px;
    
        button {
            min-width: 44px;
            color: var(--ion-color-dark);
            background: none;
    
            &:disabled {
                background-color: rgb(240, 240, 240);
            }
    
            &:enabled {
                &:hover, &:focus {
                    background-color: rgb(230, 230, 230);
                }
            }
        }
    }
    &__month-view {
        &__weekdays {
            text-align: center;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 1.2rem;
    
            &__weekday {
                padding: .5em;
            }
        }
        &__weekNumbers {
            .react-calendar__tile {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1.2rem;
                font-weight: bold;
            }
        }
        &__days {
            &__day {
                &--weekend {
                    color: rgb(209, 0, 0);
                }
                &--neighboringMonth {
                    color: rgb(117, 117, 117)
                }
            }
        }
    }
  
    &__year-view,
    &__decade-view,
    &__century-view {
        .react-calendar__tile {
            padding: 2em .5em;
        }
    }
  
    &__tile {
        max-width: 100%;
        padding: 10px 6.6667px;
        background: none;
        text-align: center;
        line-height: 16px;
        color: var(--ion-color-dark);
  
        &:disabled {
            color: var(--ion-color-dark);
            background-color: rgb(240, 240, 240);
        }
        &--now {
            background: lighten(rgb(220, 220, 0), 30%);
        }
        &--hasActive {
            color: var(--ion-color-light);
            background: var(--ion-color-primary);
        }
        &--active {
            color: var(--ion-color-light);
            background: var(--ion-color-primary);
        }
    }
    &--selectRange {
        .react-calendar__tile {
            &--hover {
                background-color: rgb(230, 230, 230);
            }
        }
    }
  }