.welcome {
    &::part(background) {
        --background: linear-gradient(0deg, rgba(255,204,131,1) 0%, rgba(253,126,173,1) 100%);
    }
    &::part(scroll) {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: max(var(--ion-safe-area-top), 16px);
        padding-bottom: max(var(--ion-safe-area-bottom), 16px);
        text-align: center;
    }
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 400px;
        height: 100%;
    }
    h1 {
        font-size: 5.5rem;
    }
    &-image {
        flex-shrink: 0;
        display: block;
        max-height: 25vh;
        min-height: 235px;
        margin: auto;
    }
    .language-selection {
        display: flex;
        justify-content: center;
        margin-bottom: 24px;

        ion-select::part(placeholder) {
            --placeholder-color: #0c2305;
            --placeholder-opacity: 1;
        }
    }
}
