.button {
    --padding-top: 20px;
    --padding-bottom: 20px;
    flex-shrink: 0;
    height: auto;
    min-height: 36px;
    font-family: "Ubuntu";
    font-size: 1.8rem;
    text-transform: none;

    &-block {
        margin: 0.4rem 0;
    }
    &-solid {
        --box-shadow: none;
    }
    &-small {
        --padding-start: 16px;
        --padding-end: 16px;
        height: 24px;
        font-size: 1.3rem;
    }
    ion-icon {
        margin-left: 10px;
    }
}
