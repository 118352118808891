ion-segment {
    border-radius: var(--flow-border-radius);
        
    .button {
        --padding-top: 5px;
        --padding-bottom: 5px;
        font-size: 1.4rem;
        font-weight: 400;

        ion-icon {
            margin-left: 0;
            font-size: 1.6rem;
        }
    }
}
ion-segment-button {
    --border-radius: var(--flow-border-radius);
    --color: var(--ion-color-light);

    &::part(indicator-background) {
        --indicator-color: var(--ion-color-primary);
    }
}