.wifi-password {
    .wifi-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: max(var(--ion-safe-area-bottom), 16px);
        background: var(--ion-color-medium);

        ion-list {
            width: 100%;
            background: var(--ion-color-light);
        }
    }
    .password {
        --padding-start: 16px;
        --inner-padding-end: 16px;
        width: 100%;
    }
}