.charts {
    h2 {
        margin-top: 8px;
        color: var(--ion-color-light);
        font-size: 1.6rem;
        text-align: center;

        sup {
            position: relative;
            top: -0.5em;
            font-size: 55%;
            line-height: 0;
            vertical-align: baseline;
        }
    }
    &__container {
        position: relative;
        margin-top: 10px;
        margin-bottom: 10px;
        background: #fff;

        .error {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px;
            color: var(--ion-color-light);
            font-size: 1.4rem;
            background: rgba(255,204,131,0.6);
            background: rgba(var(--ion-color-tertiary-rgb),0.6);
        }
    }
}
.tooltip {
    position: absolute;
    opacity: 1;
    margin-bottom: 10px;
    border-radius: 3px;
    color: white;
    pointer-events: none;
    background: rgba(0,0,0,0.7);
    transform: translate(-50%, -110%);
    transition: all .1s ease;

    &::before {
        position: absolute;
        bottom: -6px;
        left: 50%;
        content: "";
        width: 0;
        height: 0;
        margin-left: -6px;
        border-style: solid;
        border-width: 6px 6px 0 6px;
        border-color: rgba(0,0,0,0.7) transparent transparent transparent;
    }
    h2 {
        margin: 0 0 10px;
        font-size: 1.6rem;
        white-space: nowrap;
    }
}
.tooltip-value {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    white-space: nowrap;
}