.dongle {
    &::part(background) {
        --background: url(../../assets/img-dongle-v1.jpg) no-repeat top center;
        background-size: contain;
    }
    &-fixed {
        overflow: hidden;
        width: 100%;
        height: calc(100% - 160px);
        margin-top: 160px;
        border-top-left-radius: var(--flow-border-radius);
        border-top-right-radius: var(--flow-border-radius);
        text-align: center;
        background: linear-gradient(180deg, rgba(255,204,131,1) 0%, rgba(253,126,173,1) 100%);
    }
    &-content {
        touch-action: pan-y;
        overflow-y: auto;
        overscroll-behavior-y: contain;
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 16px;
        padding-bottom: max(var(--ion-safe-area-bottom), 16px); // fix for Android 10, can't seem to handle max() in padding with other values, need to put it in seperate rule
    }
    &-text {
        max-width: 300px;
        margin: 0 auto;
    }
    .dongle-link {
        display: inline-block;
        margin-top: auto;
        color: var(--ion-color-light);
    }
    .action {
        margin-top: 40px;
    }
    h1 {
        margin-top: auto;
    }
}

@media (min-width: 375px) {
    .dongle {
        &-fixed {
            height: calc(100% - 180px);
            margin-top: 180px;
        }
    }
}
@media (min-width: 390px) {
    .dongle {
        &-fixed {
            height: calc(100% - 200px);
            margin-top: 200px;
        }
    }
}