.ios,
.md {
    ion-input {

        &:not(.item-label) {
            --padding-start: 16px;
            --padding-end: 16px;
            --background: var(--ion-color-light);
            border-radius: 50px;
            color: var(--ion-color-secondary);
            
            &::placeholder {
                color: green;
            }
        }
    }
}

.input--small {
    ion-input {
        flex: 0 1 100px;
    }
    .input-symbol {
        + ion-input {
            margin-left: 8px;
        }
    }
}