.charts {
    &__nav-wrapper {
        position: relative;
        z-index: 2;
        display: flex;
        gap: 24px;
        justify-content: center;
        padding: 16px;
    }
    &__dateselect {
        text-align: center;

        .charts__dateselect--buttons {
            .calendar__trigger {
                padding: 0 8px;
            }
        }
        .button:disabled {
            opacity: 0.2;
        }
        .react-calendar {
            top: 61px;
        }
    }
    &__nav--period {
        margin: 0 20px;
    }
    &__nav--type {
        max-width: 375px;

        ion-segment-button {
            --border-width: 0;
            min-width: 40px;

            &::part(native) {
                --padding-start: 0;
                --padding-end: 0;
            }
        }
    }
}