.gdpr {
    &::part(scroll) {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: max(var(--ion-safe-area-top), 16px);
        padding-bottom: max(var(--ion-safe-area-bottom), 16px);
        text-align: center;
    }
    &-text {
        margin: auto 0;
    }
    p a {
        color: var(--ion-color-dark);
        font-weight: 700;
    }
    ion-label a {
        height: auto;
        color: var(--ion-color-light);
    }
}