.charts {
    &__overview {
        margin-top: 20px;
        padding: 15px 20px;
        border-radius: var(--flow-border-radius);
        background-color: #fff;

        h5 {
            margin: 0;
            font-size: 1rem;
            font-family: var(--flow-font-family-bold);
        }
    }
    &__overview--bloc {
        display: flex;
        align-items: center;
        margin-top: 5px;
        color: var(--ion-color-medium);
        font-size: 1rem;

        ion-icon {
            margin-right: 5px;
        }
    }
}
.chart-prices {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-top: 8px;
    padding: 0 16px;
    color: var(--ion-color-light);
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.2;
    text-align: center;
}
.chart-price {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 8px;

    @media (min-width: 375px) {
        flex-direction: row;
    }

    svg {
        width: 16px;
        height: 16px;
        border-radius: 100%;
        background: #fff;
    }
    .price-per-kwh {
        width: 100%;
        font-size: 1.2rem;
    }
    &--up {
        svg {
            color: var(--ion-color-primary);
        }
    }
    &--down {
        svg {
            color: var(--ion-color-secondary);
        }
    }
}