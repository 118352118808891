.settings {
    &::part(scroll) {
        padding-top: max(var(--ion-safe-area-top), 16px);
    }
    h1 {
        margin-top: 10px;
        margin-bottom: 8px;
        font-size: 2.5rem;
    }
}
.settings-link {
    color: var(--ion-color-light);
}
.settings-button {
    margin-bottom: 10px;

    ion-button {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
    }
}